import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FWBanner from '../../components/fullWidthBanner';
import FWTextBanner from '../../components/fullWidthTextBanner';
import FWCard from '../../components/fullWidthCard';


const useStyles = makeStyles((theme) => ({
    promoHeader: theme.typography.promoHeader,
    paragraph: {
        marginBottom: theme.spacing(5),
    },
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),

    },
}))

export default function ValuesTr(props) {
    const classes = useStyles()
    const locale = 'tr-TR'
    return (
        <Layout nodeLocale={locale} location={props.location}>
            <SEO title='Değerlerimiz' lang={locale} location={props.location} />
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWBanner imgName='degerlerimiz-desktop.jpg' />
                </Grid> 
                <Grid item xs={12} className={classes.paragraph}>
                    <Grid container className={classes.container}>
                        <FWTextBanner title='En büyük tutkumuz dört ayaklı dostlarımız' />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                   <FWCard title='Hayvan istismarına hayır' subtitle='Haberdar olduğumuz, ulaşabildiğimiz tüm hayvanların bakımlarını üstlenip, rehabilitasyonlarına destek oluyoruz. Bu konuyla alakalı destek arayışında olmanız halinde, bize ulaşıp ihbarda bulunabilirsiniz.' image='degerlerimiz-hayvan-istismarı.jpg' bg/>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                   <FWCard title='Dört ayaklı dostlarımız aç kalmasın' subtitle='Sahiplenilmemiş, barınak ve sokaklarda terk edilmiş halde olan dostlarımıza destek olup; dernekler aracılığıyla gerekli yardımları yapıyoruz. Hiçbir canlının aç kalmaması, en önemli hedef ve isteklerimizden biri.' image='degerlerimiz-ac-hayvan-kalmasin.jpg' position='right'bg/>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                   <FWCard title='Kalite ve Lezzet' subtitle="Dostlarımıza; Avrupa Birliği'ndeki tedarikçilerden gelen taze ve kaliteli içeriklerle hazırlanmış, yüksek yeme oranları ile lezzetini ispat etmiş mamalar sunuyoruz." image='degerlerimiz-kalite.jpg' bg/>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                   <FWCard title='Önceliğimiz tazelik' subtitle='Üretimden rafa, mamalarımızı mükemmel lojistik planlama ve süreç yönetimi ile, mümkün olan en hızlı şekilde ulaştırıyoruz. Bu sayede ürünlerimiz, rafta her zaman taze olarak sunuluyor.' image='degerlerimiz-taze.jpg' position='right' bg/>
                </Grid>

            </Grid>
        </Layout>
    )

}




