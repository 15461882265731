import React from 'react'
import { Paper, makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
const useStyles=makeStyles(theme =>({
    root:{
        display:'flex',
        flex: '1 1 auto',
        alignItems:'center',
        justifyContent:'center',
        minHeight: '300px',
        flexWrap:'wrap'

    },
    textContainer:{
        display: 'flex',
        flexDirection:'column',
        alignItems:'center',
        flexWrap:'wrap',
        padding:'2%',
        gap:'20px',
        
    },
    title:{
        fontFamily:['Helvetica Neue','sans-serif'],
        fontWeight:600,
        fontSize:'3rem',
        lineHeight:'2.7rem',
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.5rem',
            lineHeight:'1.5rem',
        }
    },
    bgGrey:{
        backgroundColor: theme.palette.primary.main
    },
    subtitle:{
        fontSize:'1.3rem',
        color:theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]:{
            fontSize:'1rem',
        }
    }
}))
const FWTextBanner = (props) => {
const classes = useStyles()
const {subtitle,title, bg} = props
return (
    <Paper className={`${classes.root} ${bg?classes.bgGrey:''}`} square elevation={0}>
        <div className={classes.textContainer}>
        <Typography className={classes.title} align='center' gutterBottom>{title}</Typography>
        {subtitle && <Typography className={classes.subtitle} align='center'>{subtitle}</Typography>}
        </div>
    </Paper>
)
}

export default FWTextBanner